import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useMozartColors from "../../App/hooks/useMozartColors";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    values: {
        textAlign: 'center'
    }
});

export default function ValuesWidget({ title, values }) {
    const classes = useStyles();
    const mozartColors = useMozartColors();

    return (
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <Typography variant="h6">{title}</Typography>
                <div className={classes.values}>
                    <Typography variant="h3" style={{ color: mozartColors.greenCS }}>{values}</Typography>
                </div>
            </CardContent>
            <CardActions>
                <Button size="small">Mais detalhes</Button>
            </CardActions>
        </Card>
    );
}
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import React, { useEffect, useState } from "react";
import useStyles from "../hooks/useStyles";
import Drawer from "@material-ui/core/Drawer";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItemLink from "./ListItemLink";
import HomeIcon from "@material-ui/icons/Home";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import ListItemText from "@material-ui/core/ListItemText";
import useMozartColors from "../hooks/useMozartColors";
import { useHistory } from "react-router";
import StorageService from "../services/StorageService";
import { GroupEnum } from "../../Dataset/factory/Enum";
import {Typography} from "@material-ui/core";

const MozartDrawer = (props) => {
    const classes = useStyles();
    const mozartColors = useMozartColors();
    const history = useHistory();
    const [userName, setUserName] = useState('false');

    useEffect(() => {
        setUserName(StorageService.get('name'));
    }, []);

    return (<Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
            [classes.drawerOpen]: props.open,
            [classes.drawerClose]: !props.open,
        })}
        classes={{
            paper: clsx({
                [classes.drawerOpen]: props.open,
                [classes.drawerClose]: !props.open,
            }),
        }}
    >
        <div className={classes.toolbar}>
            <IconButton onClick={() => props.setOpen(false)}>
                {props.theme.direction === 'ltr' && <ChevronLeftIcon />}
            </IconButton>
        </div>
        <Divider />
        <List>
            <ListItemLink button icon={<HomeIcon style={{ color: mozartColors.greenCS }} />} primary='Dashboard' to='/dashboard' />
            <ListItemLink button icon={<ListAltIcon style={{ color: mozartColors.greenCS }} />} primary='Dataset' to='/dataset' />
        </List>
        <Divider />
        <List>
            <ListItem button onClick={() => {
                if (window.confirm('Encerrar sessão?')) {
                    window.localStorage.removeItem('token');
                    window.localStorage.removeItem('id_group');
                    window.localStorage.removeItem('id');
                    window.localStorage.removeItem('name');
                    history.push('/dashboard');
                }
            }}>
                <ListItemIcon><ExitToAppIcon style={{ color: mozartColors.red }} /></ListItemIcon>
                <ListItemText primary='Sair' />
            </ListItem>
        </List>
    </Drawer>);
}

export default MozartDrawer;
import axios from 'axios';

const ENDPOINT = 'https://api.prod.mozart.consforsistemas.com.br/';
// const ENDPOINT = 'http://localhost:8000/';
const isValidUri = (uri) => {
    if (uri === '') {
        console.warn("ApiServiceError: INFORME A URI!", ENDPOINT);
        return false;
    }

    return true;
}
const beforeRequest = () => {
    axios.defaults.headers.common['Authorization'] = "Basic " + btoa(window.localStorage.getItem('token'));
}
const ApiService = {
    handleError: (response) => {
        switch (response.status) {
            case 401:
                window.location.href = '/login';
                break
            case 403:
                window.alert('Sem permissao para realizar esta acao!')
                break
            case 404:
                window.alert('Funcionalidade nao encontrada!')
                break
            default:
                window.alert('Erro interno no servidor.')

        }
    },
    post: async (uri, params) => {
        if (!isValidUri(uri)) return false;
        beforeRequest();
        return axios.post(ENDPOINT + uri, params, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response['data'])
            .catch(error => {
                return error
            });
    },
    patch: async (uri, params) => {
        if (!isValidUri(uri)) return false;
        beforeRequest();
        return axios.patch(ENDPOINT + uri, params, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response['data'])
            .catch(error => {
                return error
            });
    },
    get: async (uri, params) => {
        if (!isValidUri(uri)) return false;
        beforeRequest();
        return axios.get(ENDPOINT + uri, {params})
            .then(response => {
                response['data']['status'] = response['status'];
                return response['data']
            })
            .catch((error) => {
                ApiService.handleError(error.response);
                return error
            });
    },
}

export default ApiService;
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { Route, Router, Switch} from "react-router";
import AuthenticatedRouter from "./App/AuthenticatedRouter";
import Login from "./Login/Login";
import Dataset from "./Dataset/Dataset";
import Dashboard from "./Dashboard";
import {createBrowserHistory} from "history";
import {BrowserRouter} from "react-router-dom";

const history = createBrowserHistory();

ReactDOM.render(
    <Router history={history}>
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Login}/>
                <Route path="/login" exact component={Login}/>
                <AuthenticatedRouter path="/home" exact component={Dashboard}/>
                <AuthenticatedRouter path="/dashboard" exact component={Dashboard}/>
                <AuthenticatedRouter path="/dataset" exact component={Dataset}/>
            </Switch>
        </BrowserRouter>
    </Router>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, {useEffect, useState} from 'react';
import ApiService from "../App/services/ApiService";
import DatasetTableList from "./components/DatasetTableList";
import PageLoader from "../App/components/PageLoader";
import NormalizeResultSet from "../App/hooks/NormalizeResultSet";
import CreateNewDataset from "./components/CreateNewDatasetDialog";
import CreateNewDatasetDialog from "./components/CreateNewDatasetDialog";
import FloatButton from "../App/components/FloatButton";

export default function Dataset() {
    const [loading, setLoading] = useState(true)
    const [tableLoading, setTableLoading] = useState(false)
    const [idGroup, setIdGroup] = useState(0)
    const [resultSet, setResultSet] = useState([])
    const [stateNewDatasetDialog, setStateNewDatasetDialog] = useState(false)
    const [mapResultSet, setMapResultSet] = useState({});
    // const [selectedDataSet, setSelectedDataSet] = React.useState({id: 0, name: ''});

    const list = () => {
        ApiService.get('/v1/dataset', {}).then(response => {
            if (!response.success){ return false; }
            const mapResultSet = NormalizeResultSet().createMapResultSet(response.resultSet);
            updateResultSet(response.resultSet, mapResultSet);
            setMapResultSet(mapResultSet)

            setLoading(false);
        });
    }

    useEffect(() => {
        setIdGroup(Number(window.localStorage.getItem('id')));
        list();
    }, [])

    const updateResultSet = (resultSet = [], mapResultSet = {}) => {
        setResultSet(resultSet)
        setMapResultSet(mapResultSet)
    }

    const actions = {
        'annotationInsert': (dataset) => {
            if (window.confirm(`Confirmar inicio das marcacoes para o dataset ${dataset.id} - ${dataset.name} ?`)) {
                // setLoading(true);
                setTableLoading(true);
                ApiService.post('/v1/dataset/annotation', {data: {id_dataset: dataset.id}})
                    .then((response) => {
                        window.alert(response.message)

                        dataset['status_name'] = "Anotacao em Andamento";
                        dataset['id_status_dataset'] = "2";
                        if(response.success){
                            dataset['id_annotator'] = localStorage.getItem('id');
                        }

                        const mapResultSet = NormalizeResultSet().createMapResultSet([dataset])
                        updateResultSet([dataset], mapResultSet);

                        setTableLoading(false);
                    })
                setLoading(false);
            }
        },
        'supervisionInsert': (dataset) => {
            if (window.confirm(`Confirmar inicio da supervisao para o dataset ${dataset.id} - ${dataset.name} ?`)) {
                // setLoading(true);
                setTableLoading(true);
                ApiService.post('/v1/dataset/supervision', {data: {id_dataset: dataset.id}})
                    .then((response) => {
                        dataset['status_name'] = "Supervisao em andamento";
                        dataset['id_status_dataset'] = "3";
                        dataset['id_annotator'] = localStorage.getItem('id');
                        const mapResultSet = NormalizeResultSet().createMapResultSet([dataset])
                        updateResultSet([dataset], mapResultSet);
                        setTableLoading(false);
                        window.alert('Tenha um otimo trabalho nesta supervisao! ')
                    })
                setLoading(false);
            }
        },
        'insertDatasetAnnotation': (dataset) => {
            if (window.confirm(`Confirmar a entrega do dataset ${dataset.id} - ${dataset.name} ?`)) {
                // setLoading(true);
                setTableLoading(true);
                ApiService.patch('/v1/dataset/annotation', {data: {id_dataset: dataset.id}})
                    .then((response) => {
                        dataset['status_name'] = "Aguardando Supervisao";
                        dataset['id_status_dataset'] = "3";
                        dataset['id_annotator'] = localStorage.getItem('id');
                        const normalized = NormalizeResultSet().update(resultSet, mapResultSet, dataset, dataset.id)
                        updateResultSet(normalized.resultSet, normalized.mapResultSet);
                        setTableLoading(false);
                        window.alert('Muito obrigado! Agora e so aguardar a supervisao ;) ')
                    })
                setLoading(false);
            }
        },
    };



    return (<div>
        {loading ? <PageLoader/> :
            (<div>
                {idGroup === 1 && (<CreateNewDatasetDialog open={stateNewDatasetDialog}
                                                           setOpen={setStateNewDatasetDialog} />)}
                <DatasetTableList loading={tableLoading} actions={actions} resultSet={resultSet}/>
                <FloatButton onClick={() => {
                    setStateNewDatasetDialog(true)
                }} />
            </div>)}
    </div>);
}
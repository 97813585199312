import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import ApiService from '../../App/services/ApiService';
import useStyles from "../../App/hooks/useStyles";

export default function CreateNewDatasetDialog({ open, setOpen }) {
    const classes = useStyles();
    const [name, setName] = useState('');
    const [link, setLink] = useState('');

    function handleCreateDataset() {
        if (name === '' || link === '') return window.alert('Insira os campos corretamente');

        ApiService.post('v1/dataset', { data: { name, download_link: link } }).then(response => {
            if (!response.success) return window.alert('Dados incorretos, confirme e tente novamente.');
            window.alert('Cadastro realizado com sucesso!');
            handleClose();
        })
    }

    function handleClose() {
        if (typeof setOpen === 'function') setOpen();
        setName('');
        setLink('');
    }

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Cadastrar novo dataset</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className={classes.dialogContent} >
                        <TextField
                            label="Descrição"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                        <TextField
                            label="Link para download"
                            value={link}
                            onChange={(e) => setLink(e.target.value)}
                            required
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleCreateDataset} color="primary" autoFocus>
                        Cadastrar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
import React from "react";
import { Button } from "@material-ui/core";
import { StatusDatasetEnum, GroupEnum } from './Enum'

export function ButtonDatasetOptionFactory(props) {
    if (props.dataset.id_status && props.dataset.id_status)
        throw new DOMException('Parametros invalidos!');

    const idStatusDataset = parseInt(props.dataset.id_status_dataset)
    // const idUserDataset = parseInt(props.dataset.id_user)
    const idAnnotatorDataset = parseInt(props.dataset.id_annotator);
    // const idSupervisorDataset = parseInt(props.dataset.id_supervisor);
    const idGroup = parseInt(props.dataset.id_group)
    const idUserSession = parseInt(window.localStorage.getItem('id'))


    if (idStatusDataset === StatusDatasetEnum.AGUARDANDO_ANOTACAO)
        return <Button color={'primary'} variant={'outlined'} onClick={() => {
            props.actions.annotationInsert(props.dataset)
        }}>Anotar</Button>
    if (idStatusDataset === StatusDatasetEnum.ANOTACAO_EM_ANDAMENTO && idUserSession === idAnnotatorDataset)
        return <Button color={'primary'} variant={'outlined'} onClick={() => {
            props.actions.insertDatasetAnnotation(props.dataset)
        }}>Concluir Anotacoes</Button>
    if (idStatusDataset === StatusDatasetEnum.ANOTACAO_CONCLUIDA && (idGroup === GroupEnum.SUPERVISOR || idGroup === GroupEnum.MANAGER))
        return <Button color={'primary'} variant={'outlined'}>Concluir Anotacoes</Button>



    return <Button>Sem opcoes</Button>
}
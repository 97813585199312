export const StatusDatasetEnum = {
    AGUARDANDO_ANOTACAO: 1,
    ANOTACAO_EM_ANDAMENTO: 2,
    ANOTACAO_CONCLUIDA: 3,
    EM_SUPERVISAO: 1,
};

export const GroupEnum = {
    MANAGER: 1,
    SUPERVISOR: 2,
    ANNOTATOR: 3,
};
import React from 'react'
import {Redirect, Route} from "react-router";
import App from "./App";
const isAuthenticated = () => {
    const token = localStorage.getItem('token');
    try {
        if (token) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

function AuthenticatedRouter({component: Component, ...rest}) {
    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated() ? (
                    <App><Component {...props} /></App>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                        }}
                    />
                )
            }
        />
    );
}
export default AuthenticatedRouter;
const NormalizeResultSet = () => ({
    createMapResultSet: (resultSet = [], indexOfIdRegister = 'id') => {
        let mapResultSet = {};
        resultSet.map((item, index) => {
            mapResultSet[item[indexOfIdRegister]] = index;
            return item;
        })

        return mapResultSet;
    },
    update: (resultSet, mapResultSet, register, id_register) => {
        let index =  mapResultSet[id_register];
        resultSet[index] = register;
        return { resultSet, mapResultSet };
    },
    updateStatus: (resultSet, mapResultSet, register, id_register) => {
        let index =  mapResultSet[id_register];
        resultSet = Object.assign([], resultSet);
        resultSet[index].ativo = !resultSet[index].ativo

        return { resultSet, mapResultSet };
    },
    add: (resultSet, mapResultSet, register, id_register) => {
        resultSet.push(register);
        mapResultSet[id_register] = resultSet.length - 1;

        return { resultSet, mapResultSet };
    },
    remove: (resultSet, mapResultSet, id_register) => {
        let index =  mapResultSet[id_register];

        resultSet.slice(index, 1)
        delete mapResultSet[id_register];

        return { resultSet, mapResultSet };
    }
})

export default NormalizeResultSet;
import React from 'react';
import Grid from '@material-ui/core/Grid';
import './styles.css';
import ValuesWidget from "./components/ValuesWidget";


export default function Dashboard() {
    return (<Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
            <ValuesWidget title="Quantidade por dia" values={47}/>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
            <ValuesWidget title="Quantidade por mês" values={386}/>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
            <ValuesWidget title="Total" values={433}/>
        </Grid>
    </Grid>);
}

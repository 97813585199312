import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {Button} from "@material-ui/core";
import {ButtonDatasetOptionFactory} from "../factory/ButtonDatasetOptionFactory";

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 1000,
    },
    tableHead: {
        backgroundColor: 'rgb(33,33,33)',
        color: 'whitesmoke',
        fontSize: '12px',
    },
})

export default function DatasetTableList(props) {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(30);
    useEffect(() => {
    }, [ props.resultSet ])

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table" >
                    <TableHead>
                        <TableRow >
                            <TableCell className={classes.tableHead} align={'center'} style={{minWidth: 10}}>
                                NOME
                            </TableCell>
                            <TableCell className={classes.tableHead}  align={'center'} style={{minWidth: 10}}>
                                STATUS
                            </TableCell>
                            <TableCell className={classes.tableHead}  align={'center'} style={{minWidth: 10}}>
                                CADASTRO
                            </TableCell>
                            <TableCell className={classes.tableHead}  align={'center'} style={{minWidth: 10}}>
                                OPERADOR
                            </TableCell>
                            <TableCell className={classes.tableHead}  align={'center'} style={{minWidth: 10}}>
                                SUPERVISOR
                            </TableCell>
                            <TableCell className={classes.tableHead}  align={'center'} style={{minWidth: 10}}>
                                DIRETOR
                            </TableCell>
                            <TableCell className={classes.tableHead}  align={'center'} style={{minWidth: 10}}>
                                DOWNLOAD
                            </TableCell>
                            <TableCell className={classes.tableHead}  align={'center'} style={{minWidth: 10}}>
                                OPCOES
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    {props.loading && (<TableBody>
                        <TableRow >
                            <TableCell colSpan={8} align={'center'}>
                               Realizando operacoes no servidor... Por favor, aguarde =]
                            </TableCell>
                        </TableRow>
                    </TableBody>)}
                    {!props.loading && (<TableBody>
                        {props.resultSet.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((register, index) => {
                            return (
                                <TableRow hover role="checkbox" aria-checked={false} tabIndex={-1} key={index}>
                                    <TableCell  align={'center'}>
                                        {register.id} - {register.name}
                                    </TableCell>
                                    <TableCell  align={'center'}>
                                        {register.status_name}
                                    </TableCell>
                                    <TableCell  align={'center'}>
                                        {register.created_at} por {register.creator}
                                    </TableCell>
                                    <TableCell  align={'center'}>
                                        {register.annotator}
                                    </TableCell>
                                    <TableCell  align={'center'}>
                                        {register.supervisor}
                                    </TableCell>
                                    <TableCell  align={'center'}>
                                        {register.manager}
                                    </TableCell>
                                    <TableCell align={'center'} style={{minWidth: 10}}>
                                        <Button variant={'outlined'} onClick={() => {
                                            window.open(register.download_link, '_blank')
                                        }}> Download </Button>
                                    </TableCell>
                                    <TableCell align={'center'} style={{minWidth: 10}}>
                                        <ButtonDatasetOptionFactory actions={props.actions} dataset={register} />
                                    </TableCell>

                                </TableRow>
                            );
                        })}
                    </TableBody>)}
                </Table>
            </TableContainer>
            {!props.loading && (<TablePagination
                rowsPerPageOptions={[30, 60, 100]}
                component="div"
                count={props.resultSet.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />)}
        </Paper>
    );
}